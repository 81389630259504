.contact {
  background: url(/assets/img/kontakt.jpg) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
 }

 img.journey {
    padding: 2rem;
    max-width: 500px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  h2.contact-heading {
    padding: 1.8rem 0 1rem;
    margin-bottom: 0;
    font-size: 1.5625rem;
  }

  .address {
    @include grid-column(12);
    padding-bottom: 1rem;
    p {
      margin-bottom: 0.5rem;
    }
  }

@include breakpoint(medium up) {
    img.journey {
    padding: 5rem 0;
  }

  .contact {
  min-height: 712px;
 }

 .address {
    @include grid-column(6);
  }

}
