/* Footer Styles */

footer {
    color: $primary-color;
    font-size: 14px;
    padding: 1rem 0;
    bottom: 0;
    img {
        padding-left: 1rem;
        float: left;
        width: 100px;
        padding-right: 0.5rem;
    }
    ul.footer-nav {
        float: none;
        margin-bottom: 0;
        li {
            display: inline;
            a {
                color: $primary-color;
                text-transform: none;
                padding: 0.5rem;
                &:hover {
                    color: #0187b4;
                    border-bottom:none;
                    background-color: transparent;
                }
                &:focus {
                    background-color: transparent;
                    color: #0187b4;
                }
            }
        }
    }
}

footer.index-footer {
    width: 100%;
    position: fixed;
    background-color: #FFF;
}

@include breakpoint(medium up) {
    footer {
        img {
            float: left;
            padding: 0.2rem 0 0 3rem;
            width: 120px;
        }
        ul.footer-nav {
            float: right;
            padding-right: 3rem;
            li {
                a {
                    padding: 1rem;
                }
            }
        }
    }
}

