
.slick-slider {
  margin-top: 0;
}

.fillmoreInner{
  background-position: 50% 0;
}

.content {
  @include grid-column-row;
    padding-bottom: 0;
  }

img.logo {
  padding: 8rem 0 12rem;
}

.container {
  margin: 0 auto;
  margin: 2rem 1rem;
  background-color: rgba(255,255,255,0.8);
  padding: 0 1rem 1rem;
}

.aerzte-slider-nav{
  display: none;
}

.aerzte-slider img {
  max-width: 100%;
  margin: 0 auto;
}

.content-slider img {
  width: 100%;
}


/* Ärzte SLider new */

.aerzte-slider {
  @include grid-column-row;
  img {
   position: relative;
  }
}

.aerzte-slider p {
  position: relative;
  background-color: #00A3D9;
  margin: 0 auto;
  text-align: center;
  padding: 0.5rem;
  color: white;
  font-size: 1.5rem;
  font-weight: 900;
}

@include breakpoint(medium up) {
  .container {
    bottom: 9rem;
    left: 2rem;
    position: fixed;
    float: left;
    background-color: rgba(255,255,255,0.6);
  }
  .aerzte-slider-nav{
      display: block;
      @include grid-row();
      position: relative;
      margin-top: -620px;
      height: 620px;
  }
}

/* Accordion */

.accordion-title {
  font-size: 1rem;
  padding: 0.8rem 1rem;
  img {
    width: 30px;
    margin-right: 0.5rem;
  }
}

.accordion-content ul {
  list-style: none;
  li:before {
    content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: $primary-color;
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
    margin-left: -1em; /* Also needed for space (tweak if needed) */
  }
  li a {
    text-transform: none;
  }
}
