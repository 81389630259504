@import url(https://fonts.googleapis.com/css?family=Audiowide|Open+Sans:300,800);

 h1 {
  padding: 1.8rem 0 1rem;
  margin-bottom: 0;
  text-transform: uppercase;
}

.container p{
  text-align: center;
  padding: 1rem 0 0;
  margin-bottom: 0;
  font-size: 26px;
  color: $primary-color;
  .text-up {
    text-transform: uppercase;
  }
}

.container h1 {
  text-align: center;
}

h2 {
  text-transform: uppercase;
}

li a {
	text-transform: uppercase;
	&:hover,&:focus {
	background-color: $primary-color;
	color: $white;
  }
}

@include breakpoint(medium up) {

  .container p{
  font-size: 35px;
  }
}
