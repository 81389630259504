.menu li a {
    font-size: 18px;
    color: white;
    &:hover {
        border-bottom: 2px solid white;
        padding-top: 0.8rem;
        max-width: 100px;
        padding-bottom: 2px;
    }
}

.menu li.active > a {
    border-bottom: 2px solid white;
    padding: 0 0 0.4rem 0;
    max-width: 100px;
}

.telephone,
.telephone-small {
    float: right;
    vertical-align: middle;
    margin: 0;
    a {
        vertical-align: middle;
        position: relative;
        font-size: 18px;
        color: white;
        &:hover {
            color: scale-color($anchor-color, $lightness: -40%);
        }
        &:before {
            content: "\f1f8";
            font-family: "foundation-icons";
            font-size: 25px;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            display: inline-block;
            text-decoration: inherit;
            position: absolute;
            top: 2px;
            left: -30px;
        }
        &.lastnumber{
          padding-right: 0;
          &:before{
          content: "";
        }
      }
    }
}

.top-bar {
    padding-bottom: 1rem;
    .telephone a {
        display: none;
    }
}

.top-bar-left ul li {
    padding: 0.5rem 1rem;
    a {
        padding: 0 0 0.5rem 0;
        &:hover {
            padding-top: 0;
            padding-bottom: 6px;
        }
    }
}


/* Medium and up */

@include breakpoint(medium up) {

  .title-bar{
    display: none;
  }

  .top-bar {
      height: 55px;
      padding-left: 2.8rem;
      padding-top: 1rem;
      .top-bar-left ul.menu li {
          padding: 0rem 1rem;
      }
      .home a {
          padding-top: 0;
          border-bottom: 0;
          display: block;
          text-indent: -2000px;
          position: relative;
          font-size: 18px;
          &:before {
              text-indent: 0;
              display: inline-block;
              content: "\f15a";
              font-family: "foundation-icons";
              font-size: 25px;
              font-style: normal;
              font-weight: normal;
              font-variant: normal;
              text-transform: none;
              line-height: 1;
              -webkit-font-smoothing: antialiased;
              text-decoration: inherit;
              position: absolute;
              top: -5px;
              left: -12px;
          }
          &:hover {
              margin-top: 0;
          }
      }
      .telephone {
        margin: 0 1em 0 0;
        a {
          display: inline-block;
          padding-right: .5rem;
        }
        a.lastnumber {
          padding-right: 3rem;
        }
      }
  }
}
