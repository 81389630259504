/* Custom Styles Aerzte-Page */

.doctor-menu {
  position: relative;

  ul {
    margin: 0;
    list-style-type: none;
    width: 100%;
    li {
      max-width: 100%;
      height: auto;
      color: $primary-color;
      .info {
        height: 300px;
        padding: 1rem;
      }
    }
  }
}

/* Medium and up */

@include breakpoint(medium up) {
  .doctor-menu {
    min-height: 667px;
    max-width: 100%;
    background-color: rgba(0, 163, 217, 0.5);

    ul {
      li {
        float: left;
        height: 667px;
        -webkit-transition: opacity .3s ease-in-out, width .5s ease-in-out;
        -o-transition: opacity .3s ease-in-out, width .5s ease-in-out;
        transition: opacity .3s ease-in-out, width .5s ease-in-out;
        width: 25%;
        background-repeat: no-repeat;
        background-position: center;
        img{display: none;}
        .info {
          width: 100%;
          height: 667px;
          background-color: none;
          color: white;
          font-size: 24px;
          -webkit-transition: opacity .3s ease-in-out, background-color .3s ease-in-out;
          -o-transition: opacity .3s ease-in-out, background-color .3s ease-in-out;
          transition: opacity .3s ease-in-out, background-color .3s ease-in-out;
          padding: 6em 4em;
          opacity: 0;
        }
        &:hover {
            .info {
                background-color: rgba(0, 163, 217, 0.5);
                opacity: 1;
                -webkit-transition: opacity 1s ease-in-out, background-color .3s ease-in-out;
                -o-transition: opacity 1s ease-in-out, background-color .3s ease-in-out;
                transition: opacity 1s ease-in-out, background-color .3s ease-in-out;
            }
        }
        &.heiduschka{background-image:url('/assets/img/aerzte/rainer-heiduschka.jpg');}
        &.krull{background-image:url('/assets/img/aerzte/michael-krull.jpg');}
        &.hess{background-image:url('/assets/img/aerzte/kathrin-hess.jpg');}
        &.doppler{background-image:url('/assets/img/aerzte/sabine-doppler.jpg');}
      }
    }

    .small {
      width: 16.66%;
      .info {
        opacity: 0;
      }
    }

    .medium {
      width: 25%;
      .info {
        opacity: 0;
      }
    }

    .big {
      width: 50%;
      .info {
        opacity: 1;
      }
    }
  }

}
