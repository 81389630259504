.service-slider-image {
  background: url('/assets/img/leistung.jpg');
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.service-slider-icon {
  @include grid-column(2);
  img {
      padding-top: 0.5rem;
      width: 100%;
      height: auto;
  }
}

.service-slider-content {
  padding-top: 0.4rem;
  @include grid-column(10);
  h2 {
    margin-bottom: 0.5rem;
    }
    ul {
    list-style: none;
    padding:0;
    }

    li {
        text-indent: -.4em;
        position: relative;
    }

    li:before {
        color: $primary-color;
        /* For a round bullet */
        content: '\2022';
        display: block;
        position: relative;
        max-width: 0;
        max-height: 0;
        left: -10px;
        top: -20px;
        font-size: 40px;
    }
  }

  .wrapper {
  @include grid-row();
   background-color: rgba(255,255,255,0.9);
   min-height: 620px;
  }

.slider-nav{
  display: none;
}

.part {
    clear: both;
    padding-left: 1rem;
    &:first-child {
      margin-bottom: 1rem;
      padding-top: 1rem;
     }
     &:last-child {
      padding-top: 0;
     }
   ul {
    margin-bottom: 0.5rem;
   }
  }

@include breakpoint(medium up) {
  .part {
    padding: 0 4rem;
  }
  .slider-nav{
    display: block;
    @include grid-row();
    position: relative;
    margin-top: -560px;
    height: 565px;
  }

  .wrapper {
    position: relative;
  }

  .service-slider-content-centered {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 750px;
    transform: translate(-50%, -50%);
  }
}
