.button.invertbutton {
	margin-top: 2rem;
	background-color: $white;
	color: $primary-color;
	border: 2px $primary-color solid;

	&:hover,&:focus{
		background-color: $primary-color;
		color: $white;
	}
}